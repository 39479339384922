var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"valProvider",attrs:{"vid":_vm.vid,"name":_vm.$attrs.name || _vm.$attrs.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('b-field',_vm._b({staticClass:"validator-field",class:_vm.fieldClass,attrs:{"type":{
      'is-danger': errors[0] && _vm.showError,
      'is-success': validated && valid && _vm.showSuccess,
    },"message":errors}},'b-field',_vm.$attrs,false),[(_vm.mask === _vm.FIELD_MASK.PHONE)?_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-static"},[_vm._v("+36")])]):_vm._e(),_c('b-input',_vm._b({ref:"innerInput",attrs:{"expanded":_vm.mask === _vm.FIELD_MASK.PHONE,"value":_vm.displayedValue},on:{"input":_vm.setInnerValue,"blur":_vm.blur}},'b-input',_vm.$attrs,false))],1),_vm._t("default")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }