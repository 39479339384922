<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    ref="valProvider"
    v-slot="{ errors, validated, valid }"
  >
    <b-field
      v-bind="$attrs"
      :type="{
        'is-danger': errors[0] && showError,
        'is-success': validated && valid && showSuccess,
      }"
      :message="errors"
      class="validator-field"
      :class="fieldClass"
    >
      <p v-if="mask === FIELD_MASK.PHONE" class="control">
        <span class="button is-static">+36</span>
      </p>
      <b-input
        :expanded="mask === FIELD_MASK.PHONE"
        :value="displayedValue"
        @input="setInnerValue"
        v-bind="$attrs"
        @blur="blur"
        ref="innerInput"
      ></b-input>
    </b-field>

    <slot></slot>
  </ValidationProvider>
</template>

<script>
import { maskValue } from "@/utils/util";
import { FIELD_MASK } from "@/utils/const";

export default {
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      required: true,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    mask: {
      type: String,
      required: false,
    },
    showSuccess: {
      type: Boolean,
      default: true,
    },
    showError: {
      type: Boolean,
      default: true,
    },
    allowSpace: {
      type: Boolean,
      default: false,
    },
    fieldClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      innerValue: null,
    };
  },
  computed: {
    FIELD_MASK() {
      return FIELD_MASK;
    },
    displayedValue() {
      let maskedValue = maskValue(this.mask, this.innerValue);

      let innerInput = this.$refs.innerInput;
      if (innerInput != null) {
        innerInput.updateValue(maskedValue);
      }

      return maskedValue;
    },
  },
  watch: {
    // Handles internal model changes.
    innerValue() {
      if (this.innerValue || this.innerValue === 0 || this.innerValue === "0") {
        this.$emit("input", this.innerValue);
      }
    },
    // Handles external model changes.
    value: {
      immediate: true,
      handler: function (newVal) {
        this.innerValue = newVal;
      },
    },
  },
  methods: {
    blur() {
      this.$emit("blur", this.innerValue);
    },
    setInnerValue(value) {
      if (value || value === 0) {
        if (/^(?=.*\d)[\d ]+$/.test(value)) {
          if (typeof value === "string") {
            this.innerValue = value.replace(/\s/g, "");
          }
        } else {
          this.innerValue = value;
        }
      } else {
        this.innerValue = null;
      }
    },
    forceUpdate(value) {
      // Brute force the new value via $ref if the watcher is unable to pick up new changes
      this.innerValue = value;
    },
  },
};
</script>
